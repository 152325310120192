<template>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="FomoTicket"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
      ><template v-slot:pdf-content>
        <div style="margin-left: 10px">
          <table
            style="margin-top=0px; padding-top: 0px; text-align:center"
            class="table table-bordered tcktable"
          >
            <thead>
              <tr>
                <th style="width: 23%" scope="col"></th>
                <th style="width: 57%" scope="col"></th>
                <th style="width: 20%" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <td  style="margin: 0px; padding: 0px">
                  <img
                    style="min-height: 200px; border: none"
                    :src="event.banner_url"
                    width="420"
                    height="650"
                    class="img-fluid img-thumbnail"
                    alt="Sheep"
                  />
                </td>

               

          

                <td>
                  <div class="ticket-info">
                    <p
                      style="margin-right: 110px; margin-left: 110px"
                      class="date"
                    >
                      <span style="font-weight: 900">EntradaQR</span>
                      <span class="june-29">{{ ticket.name }}</span>
                      <span style="font-weight: 900">EntradaQR</span>
                    </p>
                    <div style="padding: 0px; margin: 0px" class="show-name">
                      <h1
                        v-if="event.id == 1"
                        style="padding: 0px; margin: 0px"
                      >
                        KIA OPEN - PGA TOUR LA 2023
                      </h1>
                      <h1
                        v-if="event.id == 2"
                        style="padding: 0px; margin: 0px"
                      >
                        PLAYERS PARTY
                      </h1>
                      <h1
                        v-if="event.id!=1 || event.id!=2"
                        style="padding: 0px; margin: 0px"
                      >
                        {{ event.name }}
                      </h1>

                      <h2 style="padding: 0px; margin: 0px">
                        {{ ticket.first_name }} {{ ticket.last_name }}
                      </h2>

                    </div>
                    <div class="time">

                      <p v-if="ticket.seat_name!=''" style="padding: 0px; margin: 0px">
                        Asiento: {{ticket.seat_name}}
                      </p>


                      <p v-if="event.id == 1" style="padding: 0px; margin: 0px">
                        4 al 7 de mayo, 2023
                      </p>
                      <p v-if="event.id == 1" style="padding: 0px; margin: 0px">
                        ENTRADA VÁLIDA PARA LOS 4 DÍAS DEL EVENTO
                      </p>
                      <small v-if="event.id == 1"
                        >(EntradaQR NO Válida para la fiesta de cierre)</small
                      >
                      <p v-if="event.id == 2" style="padding: 0px; margin: 0px">
                        6 de mayo, 2023
                      </p>
                      <p v-if="event.id == 2" style="padding: 0px; margin: 0px">
                        19h00-2h00
                      </p>
                      <p v-if="event.id == 3" style="padding: 0px; margin: 0px">
                        13 de mayo, 2023
                      </p>
                      <p v-if="event.id == 3" style="padding: 0px; margin: 0px">
                        Doors Open: 20h30
                      </p>


                      <p v-if="event.id == 6" style="padding: 0px; margin: 0px">
                        26 de mayo, 2023
                      </p>
                      <p v-if="event.id == 6" style="padding: 0px; margin: 0px">
                        Lago San Pablo
                      </p>


                      <p v-if="event.id == 5" style="padding: 0px; margin: 0px">
                        26 de mayo, 2023
                      </p>
                      <p v-if="event.id == 5" style="padding: 0px; margin: 0px">
                        Rooftop Selina
                      </p>


                       <p v-if="event.id == 7" style="padding: 0px; margin: 0px">
                        19 de mayo, 2023
                      </p>
                      <p v-if="event.id == 7" style="padding: 0px; margin: 0px">
                        Doors Open: 21h00
                      </p>


                      
                      <p v-if="event.id == 5" style="padding: 0px; margin: 0px">
                        17 de Septiembre, 2023
                      </p>
                      <p v-if="event.id == 5" style="padding: 0px; margin: 0px">
                        Parque Bicentenario
                      </p>



                      <p v-if="event.id == 9" style="padding: 0px; margin: 0px">
                        23 de junio, 2023
                      </p>
                      <p v-if="event.id == 9" style="padding: 0px; margin: 0px">
                        Doors Open: 21h00
                      </p>


                      
                      <p v-if="event.id == 10" style="padding: 0px; margin: 0px">
                        29 de julio, 2023
                      </p>
                      <p v-if="event.id == 10" style="padding: 0px; margin: 0px">
                        La ubicación será enviada vía mail.
                      </p>


                      <p v-if="event.id == 11" style="padding: 0px; margin: 0px">
                        8 de Julio, 2023
                      </p>
                      <p v-if="event.id == 11" style="padding: 0px; margin: 0px">
                        9am-1pm
                      </p>


                      <p v-if="event.id == 12" style="padding: 0px; margin: 0px">
                        25 de Junio, 2026
                      </p>
                      <p v-if="event.id == 12" style="padding: 0px; margin: 0px">
                        Doors Open: 21hs
                      </p>


                      <p v-if="event.id == 13" style="padding: 0px; margin: 0px">
                        15 de Julio, 2023
                      </p>
                      <p v-if="event.id == 13" style="padding: 0px; margin: 0px">
                        Doors Open: 20hs
                      </p>


                      <p v-if="event.id == 14" style="padding: 0px; margin: 0px">
                        22 de Julio, 2023
                      </p>
                      <p v-if="event.id == 14" style="padding: 0px; margin: 0px">
                        Doors Open: 16hs
                      </p>

                      <p v-if="event.id == 14" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>

                      <p v-if="event.id == 14" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable.
                      </p>


                      <p v-if="event.id == 15" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>

                      <p v-if="event.id == 15" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable.
                      </p>

                      <p v-if="event.id == 17" style="padding: 0px; margin: 0px">
                        Asiento: {{ticket.seat_name}}
                      </p>

                      <p v-if="event.id == 17" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable.
                      </p>


                      <p v-if="event.id == 18" style="padding: 0px; margin: 0px">
                        Asiento: {{ticket.seat_name}}
                      </p>

                      <p v-if="event.id == 18" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable.
                      </p>


                      <p v-if="event.id == 19" style="padding: 0px; margin: 0px">
                        26 de Agosto - 12h00
                      </p>

                      <p v-if="event.id == 19" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable. 
                      </p>



                      <p v-if="event.id == 20" style="padding: 0px; margin: 0px">
                        16-18 de Agosto
                      </p>

                      <p v-if="event.id == 20" style="padding: 0px; margin: 0px">
                        Tunas y Cabras
                      </p>


                      <p v-if="event.id == 21" style="padding: 0px; margin: 0px">
                        2 de Septiembre
                      </p>

                      <p v-if="event.id == 21" style="padding: 0px; margin: 0px">
                        IQON
                      </p>



                      <p v-if="event.id == 44" style="padding: 0px; margin: 0px">
                        2 de Noviembre
                      </p>

                      <p v-if="event.id == 44" style="padding: 0px; margin: 0px">
                        La entrada NO es reembolsable. 
                      </p>

                      
                      <p v-if="event.id == 43" style="padding: 0px; margin: 0px">
                        7 de Octubre
                      </p>

                      <p v-if="event.id == 43" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>


                      <p v-if="event.id == 24" style="padding: 0px; margin: 0px">
                        21 de Octubre
                      </p>

                      <p v-if="event.id == 24" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>


                      <p v-if="event.id == 49" style="padding: 0px; margin: 0px">
                        27 de Octubre
                      </p>

                      <p v-if="event.id == 49" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>


                      <p v-if="event.id == 83" style="padding: 0px; margin: 0px">
                        Asiento: {{ticket.seat_name}}
                      </p>

                      <p v-if="event.id == 83" style="padding: 0px; margin: 0px">
                        Cierre de Puertas 8pm
                      </p>

                      <p v-if="event.id == 83" style="padding: 0px; margin: 0px">
                        Dress Code: Black & White | E.D.D.A.
                      </p>



                     <p v-if="event.id == 95" style="padding: 0px; margin: 0px">
                        30 de Noviembre
                      </p>

                      <p v-if="event.id == 95" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>


                      <p v-if="event.id == 89" style="padding: 0px; margin: 0px">
                        1 de Diciembre
                      </p>

                      <p v-if="event.id == 89" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>


                      <p v-if="event.id == 81" style="padding: 0px; margin: 0px">
                        2 de Diciembre
                      </p>

                      <p v-if="event.id == 81" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>

                      <p v-if="event.id == 125" style="padding: 0px; margin: 0px">
                        20 de Enero
                      </p>

                      <p v-if="event.id == 125" style="padding: 0px; margin: 0px">
                        Debe presentar su cédula el día del evento.
                      </p>





                      


                    </div>
                    <p
                      v-if="event.id == 2"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Salón Quito Tenis y Golf Club El Condado</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>

                    <p
                      v-if="event.id == 1"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Quito Tenis y Golf Club El Condado</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>

                    <p
                      v-if="event.id == 3"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Zens</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Cumbaya</span>
                    </p>



                       <p
                      v-if="event.id == 7"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Zens</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Cumbaya</span>
                    </p>


                     <p
                      v-if="event.id == 6"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Lago San Pablo</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Muelle</span>
                    </p>


                    <p
                      v-if="event.id == 5"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Selina</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Baños</span>
                    </p>



                    <p
                      v-if="event.id == 10"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Secret Location</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>

                    <p
                      v-if="event.id == 11"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Hotel Miraflores</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Ambato</span>
                    </p>






                    <p
                      v-if="event.id == 8"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Parque Bicentenario</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>

                    <p
                      v-if="event.id == 9"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Sound Garden</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Tumbaco</span>
                    </p>


                    <p
                      v-if="event.id == 12"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Secret Location</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Latam</span>
                    </p>


                    <p
                      v-if="event.id == 13"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Secret Location</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Cumbaya</span>
                    </p>


                    <p
                      v-if="event.id == 14"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Tambo Mulaló</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Latacunga</span>
                    </p>

                    <p
                      v-if="event.id == 15"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Quorum</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Paseo San Francisco</span>
                    </p>

                    <p
                      v-if="event.id == 17"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Centro de Convenciones</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>


                    <p
                      v-if="event.id == 18"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>All Padel Club</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Guayaquil</span>
                    </p>


                    <p
                      v-if="event.id == 19"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Plaza de Toros San Sebastián del Rio</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Sangolqui</span>
                    </p>



                    <p
                      v-if="event.id == 20"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Tunas y Cabras</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Ibarra</span>
                    </p>


                    <p
                      v-if="event.id == 21"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Quito</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>IQON</span>
                    </p>


                    <p
                      v-if="event.id == 44"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Cuenca</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span> Cuenca y Villa Ana María</span>
                    </p>


                    <p
                      v-if="event.id == 24"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Quito</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Palma Polo Club</span>
                    </p>




                    <p
                      v-if="event.id == 43"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Ambato</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Casa de Campo</span>
                    </p>


                    <p
                      v-if="event.id == 49"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Tumbaco</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Sound Garden</span>
                    </p>




                    <p
                      v-if="event.id == 83"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Centro de Exposiciones Quito</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>


                    <p
                      v-if="event.id == 95"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Centro de Exposiciones Quito</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>




                    <p
                      v-if="event.id == 81"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Tambo Mulaló</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Latacunga</span>
                    </p>


                    <p
                      v-if="event.id == 89"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>Tambo Mulaló</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Latacunga</span>
                    </p>



                    <p
                      v-if="event.id == 125"
                      style="padding: 0px; margin: 0px"
                      class="location"
                    >
                      <span>La Bikina</span>

                      <span style="padding: 0px; margin: 0px" class="separator"
                        ><i class="fas fa-qrcode"></i></span
                      ><span>Quito</span>
                    </p>


                  </div>
                </td>
                <td>
                  <qr-code
                    :size="200"
                    :text="invoice + 'hash' + index"
                  ></qr-code>
                  <p style="padding: 0px; margin: 0px">
                    <span
                      ><i class="fas fa-qrcode"></i>{{ invoice }}|{{
                        index
                      }}</span
                    >
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  name: "PdfGeneration",
  props: {
    ticket: {
      type: Object,
    },

    index: {
      type: Object,
    },

    event: {
      type: Object,
    },

    invoice: {
      type: Number,
    },
  },

  data() {
    return {
      todos: [
        { title: "Article 3", text: "test1" },
        { title: "Article 4", text: "test2" },
        { title: "Article 5", text: "test3" },
      ],
      name: "kkakakak",
      imgBase64: null
    };
  },

  components: {
    Vue3Html2pdf,
  },

  mounted() {
    this.generateReport();
  },

  methods: {

    convertImgUrlToBase64(url) {
    let self = this;
    var image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // use it if you try in a different origin of your web

    image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        canvas.getContext('2d').drawImage(this, 0, 0);

        canvas.toBlob(
            function(source) {
                var newImg = document.createElement("img"),
                url = URL.createObjectURL(source);

                newImg.onload = function() {
                // no longer need to read the blob so it's revoked
                URL.revokeObjectURL(url);
                };

                newImg.src = url;
            },
            "image/jpeg",
            1
        );

        // If you ever stumble at 18 DOM Exception, just use this code to fix it
        // let dataUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        let dataUrl = canvas.toDataURL("image/jpeg");
        console.log("cek inside url : ", url);
            self.assignImageBase64(dataUrl);
    };
    image.src = url;
},

assignImageBase64(img) {
    this.imgBase64 = img;
},


    
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    async convertToDataURL(source) {
    const blob = await fetch(source).then((result) => result.blob());
    const dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return dataUrl;
  },

    hasDownloaded($event) {
      console.log($event);

      this.final_file = new File([$event], "ticket.pdf");
    },


    async beforeDownload({ html2pdf, options, pdfContent }) {

      var opt = {
            margin: 0,
            filename: 'time_sheet_report.pdf',
            image: { type: 'jpeg', quality: 0.20 },
            html2canvas: { scale: 2,useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
        };


      var worker = await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
        .set(opt)
        .save()
        .then(function (data) {
          return data;
        });
      console.log(worker);
      var file = new File([worker], "ticket.pdf");
      console.log(file);
      this.$emit('messageFromPdfGeneration')

    },
  },
};
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

.date {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 5px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.date span {
  width: 100px;
}

.date span:first-child {
  text-align: left;
  font-weight: 900;
  font-family: "Staatliches";
}

.date span:last-child {
  text-align: right;
  font-weight: 900;
  font-family: "Staatliches";
}

.date .june-29 {
  color: #74ccf6;
  font-size: 20px;
}

.show-name {
  font-size: 17px;
  font-family: "Raleway";
  color: black;
}

.show-name h1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #4a437e;
  font-family: "Raleway";
}

.show-name h2 {
  font-size: 25px;
}

.time {
  padding: 10px 0;
  color: #4a437e;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 700;
}

.time span {
  font-weight: 400;
  color: gray;
  font-family: "Staatliches";
}

.left .time {
  font-size: 16px;
  font-family: "Staatliches";
}

.location {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  border-top: 1px solid gray;
}

.location .separator {
  font-size: 20px;
}

.tcktable tr td:first-child {
  border: none;
}

.tcktable tr td:last-child {
  border-left: dashed;
}
</style>
    
