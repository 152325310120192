<template>
  <div style="padding-top: 10px" class="row row-equal">
    <div class="markup-tables flex">
      <va-card>
        <va-card-title>Entradas Promocionales</va-card-title>
        <va-card-content>




          <form @submit.prevent="searchEvents()">

            
            <div class="row">
            
           

<div class="flex flex-col md9 xs8">
        <div class="item">
          <va-input
                  v-model="search"
                  placeholder="Ingrese el Nombre, Apellido o Correo"
                  label="Buscar"
                >
                </va-input>
        </div>
      </div>
      <div class="flex flex-col md3 xs4">

        <div class="d-flex justify-content-end">
        <div class="item">
          <download-excel
  class="btn btn-primary"
  style="white-space: nowrap"
  :data="events1"
  :fields="json_fields"
  worksheet="Hoja1"
  name="Asistentes.xls"
>
<i class="fa fa-download" aria-hidden="true"></i> Descargar
</download-excel>

        </div>
      </div>
      </div>


                
                              
   

                
              </div>


  

    


          </form>

          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>id</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Localidad</th>
                  <th>Código</th>
                  <th>Monto</th>
                  <th>Estado</th>
                  <th>Tipo</th>
                  <th>Comentario</th>
                  <th>Teléfono</th>
                  <th>Email</th>
                  <th>Asistente</th>
                  <th v-if="event.id==13 ||event.id==14 ">Instagram</th>
                  <th v-if="event.id==14">Transporte</th>
                  <th v-if="event.id==8">Cédula</th>
                  <th v-if="event.id==8">Talla</th>
                  <th v-if="event.id==8">Edad</th>
                  <th v-if="event.id==8">Género</th>
                  <th v-if="is_staff || is_staff==true || is_staff=='true'">Asiento</th>
                  <th>Descargar</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(event_t, index) in events" :key="index">
                  
                  <td v-if="event_t.payment.id!='n/a'">{{ formatDate(event_t.payment.date) }}</td>
                  <td v-else>n/a</td>

                  <td>{{ event_t.payment.id }}</td>
                  <td>{{ event_t.first_name }}</td>
                  <td>{{ event_t.last_name }}</td>
                  <td>{{ event_t.product_name }}</td>
                  <td>{{ event_t.product_code }}</td>
                  <td>{{ event_t.amount }}</td>
                  <td v-if="event_t.payment.status==0">Pendiente</td>
                  <td v-if="event_t.payment.status==1">Emitido</td>
                  <td v-if="event_t.payment.status==3">En Revisión</td>
                  <td v-if="event_t.payment.status==4">Rechazado</td>
                  
                  <td v-if="event_t.payment.type==1">Efectivo</td>
                  <td v-else-if="event_t.payment.type==2">Trasferencia</td>
                  <td v-else-if="event_t.payment.type==3">Tarjeta</td>
                  <td v-else-if="event_t.payment.type==5">Tarjeta (POS)</td>
                  <td v-else>n/a</td>

                  <td v-if="event_t.payment.comment=='fomoaut'">n/a</td>
                  <td v-else>{{event_t.payment.comment}}</td>
                  <td >{{event_t.phone}}</td>
                  <td >{{event_t.user_email}}</td>
                  <td >{{event_t.usage}}</td>
                  <td v-if="event.id==13 ||event.id==14">{{event_t.ig}}</td>
                  <td v-if="event.id==14">{{event_t.entry}}</td>
                  <td v-if="event.id==8">{{event_t.cc}}</td>
                  <td v-if="event.id==8">{{event_t.size}}</td>
                  <td v-if="event.id==8">{{event_t.age}}</td>
                  <td v-if="event.id==8">{{event_t.gender}}</td>
                  <td v-if="is_staff || is_staff==true || is_staff=='true'">{{event_t.seat_name}}</td>
                  <td v-if="event_t.is_automatic && event.tickets_type==1"><a :href="event_t.payment.file">Descargar</a></td>
                  <td v-else-if="(!event_t.is_automatic || event.tickets_type==2) && !is_generating" @click="generatePdf(index)">Descargar</td>
                  <td v-else-if="(!event_t.is_automatic ||  event.tickets_type==2) && is_generating">Cargando...</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-end">
            <div class="md4">
              <spring-spinner
                v-if="loading"
                v-show="next"
                :size="60"
                color="blue"
              />

              <button
                v-show="next"
                v-if="!loading"
                @click="getEvents()"
                style="
                  width: 100%;
                  border: transparent;
                  background-color: blue;
                "
                class="btn btn-primary"
              >
                Cargar Más
              </button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>




    <PdfGenerationManual  v-if="is_generating" @messageFromPdfGeneration="afterPdf" :event="event" :ticket="events[pos]" :invoice="events[pos].invoice_number" />  


    
  </div>
</template>

<script>
import { apiService } from "../../common/api.services.js";
import { SpringSpinner } from 'epic-spinners';
import PdfGenerationManual from "../../components/sales/PdfGenerationManual.vue";
import JsonExcel from "vue-json-excel3";


export default {
  name: "AssistanceList",
  data() {
    return {
      events: [],
      next: null,
      loadingTable: true,
      search: "",
      loading: false,
      promoter_table:[],
      is_staff:localStorage.getItem('is_staff'),
      pos: 0,
      is_generating: false,
      events1: [],


          


      json_fields: {
                  Id: "id",
                  Nombres: "first_name",
                  Apellidos: "last_name",
                  Producto : "product_name",
                  Codigo : "product_code",
                  Monto : "amount"


                
          },


    };
  },
  components: {
    SpringSpinner,
    PdfGenerationManual,
    downloadExcel:JsonExcel,

  },

  props: {
    event: {
      required: true,
    },
  },

  computed: {
    heading() {
      return [
        {
          name: "name",
          title: "Sección",
        },
        {
          name: "name",
          title: "Entradas Emitidas",
        },
        {
          name: "name",
          title: "Entradas Restantes",
        },
      ];
    },
  },

  created() {
    this.getEvents();
  },

  methods: {


    formatDate(dateString){
              const date = new Date(dateString);
                // Then specify how you want your dates to be formatted

                console.log(date)
            return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
    },



    populateBar() {
      this.generatedBar = {
        labels: ["Tarjeta", "Transferencia", "Efectivo"],
        datasets: [
          {
            label: "$",
            backgroundColor: "blue",
            data: [this.card, this.bank, this.cash],
          },
        ],
      };
    },

    populateData() {
      let labelArr = [];
      let backgroundColorArr = [];
      let dataArr = [];

      for (let index = 0; index < this.eventTickets.length; index++) {
        console.log("paso");

        let lname =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        labelArr.push(lname);

        let maxVal = 0xffffff; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        let color = `#${randColor.toUpperCase()}`;
        backgroundColorArr.push(color);

        let value =
          this.eventTickets[index].initial_quantity -
          this.eventTickets[index].remaining_tickets;
        dataArr.push(value);

        let obj = Object();
        obj.section =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        obj.tickets = value;
        obj.remaining = this.eventTickets[index].remaining_tickets;
        this.total_tickets.push(obj);
      }
      this.loadingTable = false;
      console.log(labelArr);
      console.log(dataArr);
      console.log(backgroundColorArr);

      this.generatedData = {
        labels: labelArr,
        datasets: [
          {
            label: "Tickets x Sección",
            backgroundColor: backgroundColorArr,
            data: dataArr,
          },
        ],
      };
    },

    openEvent(_id) {
      this.$router.push({ name: "EventHostView", params: { slug: _id } });
    },

    searchEvents() {
      this.events = [];
      this.getEvents();
      this.next = null;
    },

    populateDataS(){

      

        let money_count=0
        let sale_count=0


        let array= this.events
        let code_n=array[0].product_code


      





        for (let index = 0; index < array.length; index++) {
          if (array[index].product_code==code_n){
          money_count=parseFloat(money_count)+parseFloat(array[index].amount)
          sale_count++;
         
        }else{
          let payment_obj = Object()
          payment_obj.money=money_count
          payment_obj.code=array[index].product_code
          payment_obj.count=sale_count


          this.promoter_table.push(payment_obj)
          money_count=0
          sale_count=0
          code_n=array[index].product_code
          console.log(code_n)

        }
        }
    },

    generatePdf(p){
      this.pos=p
      this.showPdf=true
      this.is_generating=true

    },

    afterPdf(){
      this.pos=0
      this.showPdf=false
      this.is_generating=false

    },

    getEvents() {
      // make a GET Request to the questions list endpoint and populate the questions array

      //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      let endpoint = "/api/invoice-items/?event=" + this.event.id;


      if (this.search != "") {
        endpoint =
          "/api/invoice-items/?&search=" +
          this.search +
          "&event=" +
          this.event.id;
      }

      if (this.next) {
        endpoint = this.next;
        this.loading=true
      }
      this.loadingQuestions = true;
      apiService(endpoint).then((data) => {
        this.events = this.events.concat(data);
        this.showLoading = false;
        this.loading=false
        if (data.next) {
          //si no es null
          this.next = data.next;
        } else {
          //Si no es null
          this.next = null;
        }
        this.populateDataS()


// OR
this.events1=this.events
this.events1 = this.events1.filter(event => {
  return event.status ==1;
});

      });



      
    
    
    },

  },
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
