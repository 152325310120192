<template>

<div id="modalPaybox"></div>
<button id="pay" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="far fa-credit-card"></i> {{pname}}</button>


</template>




<script>
export default {
  name: 'VuePagoPlux',


  data() {
    return {
      paid: false,
      data: {}
    };
  },


props:{

email: {
    type: String
 },

 pname: {
    type: String
 },

 total: {
    required: true
 },

 phone:{
  required: false
 },

 address:{
  required: false
 },
 event:{
  required: true
 }
 

},
  


  async mounted() {

    const that = this

    let phone=this.phone

    if (this.phone==""){
      phone="999999990"

    }

    let data = {
/* Requerido. Email de la cuenta PagoPlux del Establecimiento */
PayboxRemail: "fomoplanes@sudenec.com",
/* Requerido. Email del usuario que realiza el pago */
PayboxSendmail: this.email,
/* Requerido. Nombre del establecimiento en PagoPlux */
PayboxRename: "FOMO PLANES",
/* Requerido. Nombre del usuario que realiza el pago */
PayboxSendname: this.email,
/* Requerido. Monto total de productos o servicios que no aplican
impuestos, máximo 2 decimales. Ejemplo: 100.00, 10.00, 1.00 */
PayboxBase0: this.total,
/* Requerido. Monto total de productos o servicios que aplican
impuestos, el valor debe incluir el impuesto, máximo 2 decimales.
Ejemplo: 100.00, 10.00, 1.00 posee el valor de los productos con su
impuesto incluido */
PayboxBase12: "0.00",
/* Requerido. Descripción del pago */
PayboxDescription: "Entradas QR Fomo Planes-" + this.event,
/* Requerido Tipo de Ejecución
* Production: true (Modo Producción, Se procesarán cobros y se
cargarán al sistema, afectará a la tdc)
* Production: false (Modo Prueba, se realizarán cobros de prueba y no
se guardará ni afectará al sistema)
*/
PayboxProduction: true,
/* Requerido Ambiente de ejecución
* prod: Modo Producción, Se procesarán cobros y se cargarán al
sistema, afectará a la tdc.
* sandbox: Modo Prueba, se realizarán cobros de prueba
*/
PayboxEnvironment: "sandbox",
/* Requerido. Lenguaje del Paybox
* Español: es | (string) (Paybox en español)
*/
PayboxLanguage: "es",
/* Requerido. Identifica el tipo de iframe de pagoplux por defecto true
*/
PayboxPagoPlux: true,
/*
* Requerido. dirección del tarjetahabiente
*/
PayboxDirection: this.address,
/*
* Requerido. Teléfono del tarjetahabiente
*/
PayBoxClientPhone: phone,
/*
* Requerido. Identificación del tarjetahabiente
*/
PayBoxClientIdentification: 'string',

/* SOLO PARA PAGOS RECURRENTES
* Solo aplica para comercios que tengan habilitado pagos
recurrentes
*/
/* Requerido
True -> en caso de realizar un pago recurrente
False -> si es pago normal
*/
PayboxRecurrent: false,
/* Requerido
Id o nombre exacto del plan registrado en el comercio en la
plataforma de pagoplux
*/
PayboxIdPlan: 'Plan Mensual',
/**
* true -> los cobros se realizan de manera automática según la
frecuencia del plan asignado en PAGOPLUX
* false -> los cobros se realizan mediante solicitud
*/
PayboxPermitirCalendarizar: true,
/* Requerido
* true -> El débito se realiza en el momento del pago
* false -> El débito se realizará en la fecha de corte según el plan
contratado
*/
PayboxPagoInmediato: false,
/* Requerido
true -> si desea realizar un pago de prueba de 1$ y reverso del
mismo de manera automática
NOTA: PayboxPagoImediato debe ser igual false
false -> no se realizará ningún cobro de prueba
*/
PayboxCobroPrueba: false,


onAuthorize: (response) => {

      if (response.status === 'succeeded') {    
       console.log(response.detail)    
        let type = 0
        if (response.detail.cardType=="debit"){
          type=1
        }else if(response.detail.cardType=="credit"){
          type=2
        }


        that.$emit('messageFromPagoPlux', true, response.detail.id_transaccion, response.detail.token, type)
      }
}

  }


    if (window.Data) {
      window.Data.init(data);

    }



    
  },
   


  created(){
    let data = {
/* Requerido. Email de la cuenta PagoPlux del Establecimiento */
PayboxRemail: "fomoplanes@sudenec.com",
/* Requerido. Email del usuario que realiza el pago */
PayboxSendmail: "string",
/* Requerido. Nombre del establecimiento en PagoPlux */
PayboxRename: "string",
/* Requerido. Nombre del usuario que realiza el pago */
PayboxSendname: "string",
/* Requerido. Monto total de productos o servicios que no aplican
impuestos, máximo 2 decimales. Ejemplo: 100.00, 10.00, 1.00 */
PayboxBase0: "string",
/* Requerido. Monto total de productos o servicios que aplican
impuestos, el valor debe incluir el impuesto, máximo 2 decimales.
Ejemplo: 100.00, 10.00, 1.00 posee el valor de los productos con su
impuesto incluido */
PayboxBase12: "string",
/* Requerido. Descripción del pago */
PayboxDescription: "string",
/* Requerido Tipo de Ejecución
* Production: true (Modo Producción, Se procesarán cobros y se
cargarán al sistema, afectará a la tdc)
* Production: false (Modo Prueba, se realizarán cobros de prueba y no
se guardará ni afectará al sistema)
*/
PayboxProduction: false,
/* Requerido Ambiente de ejecución
* prod: Modo Producción, Se procesarán cobros y se cargarán al
sistema, afectará a la tdc.
* sandbox: Modo Prueba, se realizarán cobros de prueba
*/
PayboxEnvironment: "sandbox",
/* Requerido. Lenguaje del Paybox
* Español: es | (string) (Paybox en español)
*/
PayboxLanguage: "es",
/* Requerido. Identifica el tipo de iframe de pagoplux por defecto true
*/
PayboxPagoPlux: true,
/*
* Requerido. dirección del tarjetahabiente
*/
PayboxDirection: "string",
/*
* Requerido. Teléfono del tarjetahabiente
*/
PayBoxClientPhone: "número telefónico del tarjetahabiente",
/*
* Requerido. Identificación del tarjetahabiente
*/
PayBoxClientIdentification: 'string',
/* SOLO PARA PAGOS RECURRENTES
* Solo aplica para comercios que tengan habilitado pagos
recurrentes
*/
/* Requerido
True -> en caso de realizar un pago recurrente
False -> si es pago normal
*/
PayboxRecurrent: true,
/* Requerido
Id o nombre exacto del plan registrado en el comercio en la
plataforma de pagoplux
*/
PayboxIdPlan: 'Plan Mensual',
/**
* true -> los cobros se realizan de manera automática según la
frecuencia del plan asignado en PAGOPLUX
* false -> los cobros se realizan mediante solicitud
*/
PayboxPermitirCalendarizar: true,
/* Requerido
* true -> El débito se realiza en el momento del pago
* false -> El débito se realizará en la fecha de corte según el plan
contratado
*/
PayboxPagoInmediato: false,
/* Requerido
true -> si desea realizar un pago de prueba de 1$ y reverso del
mismo de manera automática
NOTA: PayboxPagoImediato debe ser igual false
false -> no se realizará ningún cobro de prueba
*/
PayboxCobroPrueba: false,


  }

  this.data=data

},

methods:{

    onAuthorize : function(response) {

      if (response.status == 'succeeded') 
  {

    console.log(response)
       // Si el pago fue exitoso se ejecutará el código contenido en este if.
    //VALORES OBTENIDOS EN CASO DE ÉXITO, PARA VALIDAR TRANSACCIONES
       
   } 

  },


    
  


},




};
</script>



<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 2em;
}
</style>