<template>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="400"
      filename="FomoTicket"
      :pdf-quality="1.5"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div class="ticket-container" id="ticket-container">
          <table class="ticket-table">
            <tbody>
              <tr v-for="(ticket, index) in ticketsTable" :key="index" class="ticket-row">
                <td class="ticket-cell">
                  <!-- Perforaciones simuladas -->
                  <div class="ticket-perforations">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>

                  <!-- Contenido del ticket con 3 columnas -->
                  <div class="ticket-content">
                    <div class="ticket-columns">
                      
                      <!-- Columna 1: Imagen del banner -->
                      <div class="ticket-column">
                        <img
                          :src="event.banner_url"
                          alt="Event Banner"
                          class="ticket-banner"
                        />
                      </div>

                      <!-- Columna 2: Datos del evento centrados -->
                      <div class="ticket-column ticket-info">
                        <div class="ticket-info-content">
                          <!-- Logo centrado -->
                          <img
                            src="https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS%20FOMO%20TODAS%20LAS%20VARIACIONES-02.png"
                            alt="Fomoplanes Logo"
                            class="ticket-logo"
                          />

                          <!-- Nombre 
                           del evento -->
                          <h1 class="ticket-event-name">{{ event.name }}</h1>

                          <!-- Localidad -->
                          <h3 class="ticket-locality">Localidad: {{ ticket.name }}</h3>

                          <!-- Nombre del asistente -->
                          <h2 class="ticket-assistant-name">{{ ticket.first_name }} {{ ticket.last_name }}</h2>

                          <!-- Fila de Fecha y Asiento con líneas arriba y abajo -->
                          <div class="ticket-details-row">
                            <p class="ticket-event-details">
                              <i class="fas fa-calendar-alt"></i> {{ formatStartDate(event.start_date) }}
                            </p>
                            <p class="ticket-event-details">
                              <i class="fas fa-chair"></i> Asiento: {{ ticket.seat_name }}
                            </p>
                          </div>

                          <!-- Lugar -->
                          <p class="ticket-event-location">
                            <i class="fas fa-map-marker-alt"></i> Ubicación: {{ event.venue.name }}
                          </p>
                        </div>
                      </div>

                      <!-- Columna 3: Código QR con borde blanco -->
                      <div class="ticket-column qr-section">
                        <div class="qr-wrapper">
                          <qr-code :size="120" :text="invoice + 'hash' + index" class="ticket-qr"></qr-code>
                        </div>
                        <p class="ticket-id">{{ invoice }} | {{ index }}</p>
                      </div>

                    </div>
                  </div>

                  <!-- Marca de agua del logo arriba -->
                  <div class="watermark-logo">
                    <img
                      src="https://gingopolisdjango.blob.core.windows.net/fomoapp/media/LOGOS%20FOMO%20TODAS%20LAS%20VARIACIONES-02.png"
                      alt="Fomoplanes Logo"
                      class="watermark-logo-image"
                    />
                  </div>

                  <!-- Marca de agua del número de ticket en el centro -->
                  <div class="watermark-ticket-number">
                    <span>{{ invoice }}-{{ index }}</span>
                  </div>

                  <!-- Marca de agua del nombre del evento abajo -->
                  <div class="watermark-event-name">
                    <h1>{{ event.name }}</h1>
                  </div>

                  <!-- Elementos gráficos modernos -->
                  <div class="graphic-elements"></div>

                  <!-- Número del ticket en la parte superior derecha -->
                  <div class="ticket-number">
                    Ticket No: {{ invoice }}-{{ index }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  name: "PdfGeneration",
  props: {
    ticketsTable: {
      type: Array,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    invoice: {
      type: Number,
      required: true,
    },
  },
  components: {
    Vue3Html2pdf,
  },
  mounted() {
    this.generateReport();
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      const worker = await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob");
      const file = new File([worker], "ticket.pdf");
      this.$emit("messageFromPdfGeneration", file);
    },
    formatStartDate(startDate) {
      const date = new Date(startDate);
      return date.toLocaleDateString(); 
    },
  },
};
</script>

<style scoped>
/* Evitar saltos de página dentro de los tickets */
.ticket-row {
  page-break-inside: avoid;
}

/* Estilos principales para centrar todo correctamente y mejorar la estética */

/* Evita márgenes adicionales que generen una página en blanco */
html, body, #ticket-container {
  margin: 0;
  padding: 0;
  height: 100%;
}

.ticket-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ticket-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 15px;
  background: radial-gradient(circle, rgb(10, 2, 49) 20%, rgb(0, 0, 0) 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.ticket-cell {
  position: relative;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

/* Contenedor principal de las columnas */
.ticket-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Columnas del ticket */
.ticket-column {
  flex: 1;
  text-align: center;
}

/* Contenido del ticket con datos centrados verticalmente */
.ticket-info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Imagen del banner */
.ticket-banner {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #333;
}

/* Logo del ticket */
.ticket-logo {
  width: 100px;
  height: auto;
  margin-bottom: 5px;
}

/* Nombre del evento */
.ticket-event-name {
  color: #fff;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

/* Localidad */
.ticket-locality {
  font-size: 14px;
  color: #ddd;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 10px;
}

/* Nombre del asistente */
.ticket-assistant-name {
  font-size: 16px;
  color: #ddd;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 5px;
}

/* Fila de detalles con líneas arriba y abajo */
.ticket-details-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}

/* Detalles del evento */
.ticket-event-details {
  color: #ddd;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
}

/* Ubicación */
.ticket-event-location {
  color: #bbb;
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  margin-top: 5px;
}

/* Sección del QR con borde blanco */
.qr-section {
  text-align: center;
}

.qr-wrapper {
  padding: 10px;
  display: inline-block;
}

.ticket-qr {
  border: 6px solid #fff;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

/* Marca de agua del logo */
.watermark-logo {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.05;
}

.watermark-logo-image {
  width: 300px;
  height: auto;
}

/* Marca de agua del número de ticket */
.watermark-ticket-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  opacity: 0.05;
  color: #fff;
}

/* Marca de agua del nombre del evento */
.watermark-event-name {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 50px;
  opacity: 0.05;
  color: #fff;
}

/* Número del ticket */
.ticket-number {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  color: #999;
}

/* Elementos gráficos */
.graphic-elements {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.1));
  transform: rotate(45deg);
  opacity: 0.5;
}

/* Perforaciones simuladas */
.ticket-perforations {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}
</style>
