
<template>
<div>
<HeaderMain  v-show="!loadingEvent" @messageFromHeader="searchEvents"/>
<div>

</div>
<div>
       <SlideShow/>
       <SearchCourse v-if="!main" :courses="search" />

 <body>

    


    <section  class="clean-block clean-product dark" style="background: rgb(10,2,49);   background: radial-gradient(circle,rgb(10,2,49) 20%, rgb(0,0,0) 100%);">
      
      
      <PreloaderComponent v-show="validateProc()===false"/>

      
      <div  v-show="validateProc()===true" class="container" style="padding-top: 40px; padding-bottom: 40px;">


                <div class="block-content" style="background: transparent; border: 2px solid rgba(204,204,204,0.36); border-radius: 30px; padding: 20px;">
                    <div class="product-info container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="gallery" style="background: #4e4e4e8f;">
                                    <div id="product-preview" class="vanilla-zoom">
                                          <img :src="event.banner_url" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info">
                                    <h3 class="va-h4" style="margin-bottom: 15px;color: rgb(255,255,255);font-family: Quicksand, sans-serif;">{{event.name}}</h3>
                                    <h6 class="blog-category blog-text-success" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i :class="category.icon"></i>&nbsp;{{category.name}}</h6>
                                    <h6 class="blog-category blog-text-success" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i class="fas fa-map-marker-alt"></i>&nbsp;{{venue.name}}. {{city}}</h6>
                                    <div class="ftr">
                                        <div class="stats" style="color: rgb(198,197,197);font-family: Raleway, sans-serif;"><i class="far fa-clock"></i><span>&nbsp;{{formatDate(event.start_date)}}</span><span v-if="event.show_end_date==true">&nbsp; - {{formatDate(event.end_date)}}</span></div>
                                    <div class="row height d-flex justify-content-center align-items-center">

                      
                      <div style="padding-top: 10px;" class="col-md-8">

                          </div>

                          <div class="ftr" v-if="event.id==1">
                            <div class="alert alert-primary" role="alert" style="border-color: transparent; padding: 5px; margin: 0px; background-color:rgb(52,58,64); color:#ffffff;">
                                        
                                        <strong style="color: goldenrod">★</strong> <strong> FOMOCLUB</strong>-Este evento cuenta con FOMOPLANES y sus socios para la producción y/o organización de la experiencia.</div>

                      
                      <div style="padding-top: 10px;" class="col-md-8">

                          </div>

                        </div>



      

                            
                        
                         <div class="form-group" v-if="!code">
                        <div v-if="showCode" class="searchcode" style="background-color: rgb(52,58,64);">
                          <input  type="text" v-model="promoCode" style="background-color: rgb(52,58,64); font-family: Quicksand, sans-serif;color: rgb(255,255,255);  text-align: left !important;" class="form-control" placeholder="¿Tienes un código?">
                          <button @click="newEventTicketsSearch()" class="btn btn-primary btn-sm">Aplicar</button>
                        </div>


                        <p @click="showCode=true" v-if="!showCode" style="color: white ;font-family: Raleway, sans-serif; margin-bottom: 0px; padding-bottom: 1px;"><strong>¿Tienes un Código Promocional? Click Aquí</strong></p>

                        <h6 v-if="errCode" class="blog-category blog-text-success" style="color: red ;font-family: Raleway, sans-serif;">Código no encontrado.</h6>

                          </div>

                        <div v-if="eventTickets.length!=0" v-show="this.step==0" class="form-group"><button @click="startSale()" href="#"  class="btn btn-primary btn-block" type="submit" style="font-family: Quicksand, sans-serif; box-shadow: 0 4px 15px rgba(42, 120, 234, 0.75); transition: transform 0.3s ease, box-shadow 0.3s ease;"><i style="color: #ffffff;" class="fas fa-qrcode"></i>{{event_access}}</button></div>


                        
                      </div>
                      
                    </div>

                         
                                    <div style="padding-top: 10px;" class="summary" ref="anchor1" id="anchor1">
                                        <p style="color: rgb(255,255,255);font-family: Raleway, sans-serif; white-space: pre-wrap; text-align: justify;">{{event.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <section >
                   
                    <div  id="detailpre" v-if="step==0" class="product-info" style="padding-top: 20px;">
                         <EventViewDetail :updatesProp="updates" :eventTickets="eventTickets" :eventType="event.access"/>  
                    </div>

                    <div v-if="step==1" class="product-info" style="padding-top: 20px;">
                        
                         <SaleQuantity  @messageFromCart="startUserCreation" :eventTickets="eventTickets" :coordinated_fee="event.coordinated_fee" :coordinated_service="event.coordinated_service" :fixed_fee="event.fixed_fee"/>  

                    </div>




                    <template v-if="step==5">
  <VaButton style="margin: 20px;" @click="showModal = !showModal">
    Abrir mapa
  </VaButton>

  <VaModal
    v-model="showModal"
    overlay-opacity="0.2"
    no-outside-dismiss
    fullscreen
    backgroundColor	="rgb(10,2,49)"

    

  >

  <h3 class="va-h3">
      Selección de Asientos
    </h3>


  <div v-for="(product, index)  in seatsProducts" :key="index ">
                          <VenueMap  @messagefromVenueMap="startUserCreation1" v-if="index==mapValidator" :id="product.id" :quantityM="product.quantity_factor*product.quantity"/>  
                        </div>
  </VaModal>
</template>
                       
      


                  

                    <div v-if="step==2 " class="product-info" style="padding-top: 20px;">
                      <GuestNameBar v-if="event.access==5" @messageFromGuestName="startFinalSale" :event="event" :productsProp="products" :order_finalF="order_total" :selectedSeats="selectedSeats" :seatsProducts="seatsProducts"/>  
                         
                      <GuestName v-if="event.access!=5" @messageFromGuestName="startFinalSale" :event="event" :productsProp="products" :order_finalF="order_total" :selectedSeats="selectedSeats" :seatsProducts="seatsProducts"/>  
                         
                    </div>


                    <div v-if="step==3" class="product-info" style="padding-top: 20px;">
                         <CustomerData @messageFromCustomerData="completeSale" :ticketsTable="ticketsTable" :totalProp="order_total" :subtotalProp="order_subtotal" :feeProp="order_fee" :posProp="event.pos" :customer_prop="customer"  :event="event" :code="code"/>  
                    </div>

                    <div v-if="step==4 && saleResult==1" class="product-info" style="padding-top: 20px;">
                      <p>Fomo plan activado. Tus accesos han sido enviados al correo registrado y puedes obtenerlos desde la opción <strong>Mis Planes</strong></p>
                    </div>

                    <div v-if="step==4 && saleResult==2" class="product-info" style="padding-top: 20px;">
                      <p>Fomo plan activado. Tus accesos han sido enviados al correo registrado y puedes obtenerlos desde la opción <strong>Mis Planes</strong></p>
                    </div>

                  </section>              
                    
                    <div class="clean-related-items"></div>
                </div>
            </div>
  </section>




 </body>

    <footer v-show="!loadingEvent">
        <FooterMain/>
    </footer>


</div>
</div>

</template>

<script>
// @ is an alias to /src
import { apiService } from "../common/api.services.js";

import HeaderMain from "@/components/HeaderMain.vue"
import FooterMain from "@/components/FooterMain.vue";
import EventViewDetail from "@/components/EventViewDetail.vue";
import baguetteBox from "../../node_modules/baguettebox.js"
import SaleQuantity from "@/components/sales/SaleQuantity.vue"
import CustomerData from "@/components/sales/CustomerData.vue"
import GuestName from "@/components/sales/GuestName.vue"
import GuestNameBar from "@/components/sales/GuestNameBar.vue"
import VenueMap from "@/components/sales/VenueMap.vue"
import PreloaderComponent from "@/components/PreloaderComponent.vue"


export default {
  name: "EventView",

   props: {

    slug: {
      required: true
    },
    

    //log25
    
  },

 


  data(){
    return{
      search:[],
      searchField:"",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      showLoading: false,
      event: {},
      venue: {},
      category: {},
      city: "",
      eventTickets: [],
      updates: [],
      host: null,
      code: null,
      next: null,
      errCode: false,
      promoCode: "",
      saleMode: false,
      order_fee: 0,
      order_total:0,
      products:0,
      step: 0,
      name: "",
      last_name: "",
      phone: "",
      email: "",
      cc: "",
      ticketsTable: [],
      order_subtotal: 0,
      wanted: false,
      saleResult: 0,
      auto_sale: false,
      id: null,
      main_fn:"",
      main_ln:"",
      main_ph:"",
      customer: {},
      map: false,
      selectedSeats: [],
      seatsProducts: [],
      mapValidator: 0,
      mapLenght: 0,
      showCode: false,
      loadingEvent: false,
      event_access: " Comprar Tickets",
      showModal: true,
      pixelId: null,
      scriptLoaded: false,

     

    };
  },
  components: {
    HeaderMain,
    FooterMain,
    EventViewDetail,
    SaleQuantity,
    CustomerData,
    GuestName,
    VenueMap,
    PreloaderComponent,
    GuestNameBar
  

  },

  mounted() {
    //require('../assets/js/theme.js')
// Custom theme code


var vanillaZoom = {};
        vanillaZoom.init = function(el) {

            var container = document.querySelector(el);
            if(!container) {
                console.error('No container element. Please make sure you are using the right markup.');
                return;
            }

            var firstSmallImage = container.querySelector('.small-preview');
            var zoomedImage = container.querySelector('.zoomed-image');

            if(!zoomedImage) {
                console.error('No zoomed image element. Please make sure you are using the right markup.');
                return;
            }

            if(!firstSmallImage) {
                console.error('No preview images on page. Please make sure you are using the right markup.');
                return;
            }
            else {
                // Set the source of the zoomed image.
                zoomedImage.style.backgroundImage = 'url('+ firstSmallImage.src +')';
                firstSmallImage.classList.add('active');
            }   

            // Change the selected image to be zoomed when clicking on the previews.
            container.addEventListener("click", function (event) {
                var elem = event.target;

                if (elem.classList.contains("small-preview")) {
                    
                    var allSmallPreviews = container.querySelectorAll(".small-preview");
                    
                    allSmallPreviews.forEach(function (preview) {
                        preview.classList.remove('active');
                    })
                    
                    elem.classList.add('active');
                    
                    var imageSrc = elem.src;
                    zoomedImage.style.backgroundImage = 'url('+ imageSrc +')';
                }
            });
            
            // Zoom image on mouse enter.
            zoomedImage.addEventListener('mouseenter', function(e) {
                this.style.backgroundSize = "250%"; 
                console.log(e)
            }, false);


            // Show different parts of image depending on cursor position.
            zoomedImage.addEventListener('mousemove', function(e) {
                
                // getBoundingClientReact gives us various information about the position of the element.
                var dimentions = this.getBoundingClientRect();

                // Calculate the position of the cursor inside the element (in pixels).
                var x = e.clientX - dimentions.left;
                var y = e.clientY - dimentions.top;

                // Calculate the position of the cursor as a percentage of the total width/height of the element.
                var xpercent = Math.round(100 / (dimentions.width / x));
                var ypercent = Math.round(100 / (dimentions.height / y));

                // Update the background position of the image.
                this.style.backgroundPosition = xpercent+'% ' + ypercent+'%';
            
            }, false);


            // When leaving the container zoom out the image back to normal size.
            zoomedImage.addEventListener('mouseleave', function(e) {
                console.log(e)
                this.style.backgroundSize = "contain"; 
                this.style.backgroundPosition = "left center"; 
            }, false);

        }


if (document.getElementsByClassName('clean-gallery').length > 0) {
   baguetteBox.run('.clean-gallery', { animation: 'slideIn' });
}

if (document.getElementsByClassName('clean-product').length > 0) {
    window.onload = function() {
        vanillaZoom.init('#product-preview');
    };
}

    },

  created(){
    
   

    this.getEvent()




  },
  methods:{
      getEvent() {
      this.wanted = this.$route.query.wanted



      
      this.loadingEvent=true
      // make a GET Request to the questions list endpoint and populate the questions array


      let endpoint = `/api/events/${this.slug}/`;

      console.log(this.slug)

      if (this.slug=="PSYCHO-LAB-HORROR-NIGNT"  ){
        endpoint = `/api/events/PSYCHO-LAB-HORROR-NIGHT/`;
      }

      


      this.showLoading = true;
      apiService(endpoint).then((data) => {
        this.event = data;

        //funciona solo MULALO 2 DIC 23
        if (this.event.id==90){
          window.location.href = "/event/Tambo/";
        }

        if (this.event.access!=1){
          this.showCode=true

        }
        this.id=data.id
        this.category = data.category
        this.venue = data.venue
        this.city = data.venue.city.name
        this.showLoading = false;
        this.updates = data.updates
        this.event_id = data.id
        
        if(data.meta_pixel!=""){
          this.pixelId=data.meta_pixel
        }

        this.getEventTickets()
        if (data.access==6){
          this.event_access=" Aplicar a Grupo"
        }
        

      });
      

     
    },

    
    loadMetaPixel() {
      if (this.pixelId) {
        console.log("oasi");
        (function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', this.pixelId);
        window.fbq('track', 'PageView');
      }
    },


    startSale(){

      this.saleMode=true
      this.step=1
      this.$refs['anchor1'].scrollIntoView({behavior: "smooth"})


    },
    getEventTickets() {
       
      
      
      this.code = this.$route.query.co
      this.wanted = this.$route.query.wanted



// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = "/api/event-tickets/?event=" + this.id
        console.log(endpoint)
        if (this.code){
            endpoint = endpoint + "&code=" + this.code 
        }

          if (this.host){
            endpoint = endpoint + "&host=" + this.host 
        }
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);
          this.showLoading = false;
          this.loadingEvent=false



          if(this.$route.query.step==3){
            this.step=3

    
     }

        
        if (this.wanted){
          this.startSale()

        }

        if (this.pixelId!=null){
          this.loadMetaPixel();
        }



        })

       
      
        
    },
   



newEventTicketsSearch() {


// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      
          let   endpoint = "/api/event-tickets/?event=" + this.id + "&code=" + this.promoCode 
      
        
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);

          if (data.length==0){
            this.errCode=true
          }else{
             this.errCode=false
             this.code=this.promoCode
          }
          this.showLoading = false;
        })
      
        
    },
   
  startUserCreation(products, subtotal, fee, total, map){
    console.log("muestro los productos")
  

    console.log(products)
    this.products=products
    console.log(this.products.length)
    this.order_total=total
    this.order_fee=fee
    this.order_subtotal=subtotal
   

    if (this.event.id==16){ //modificar luego
      this.customer=1 
      this.step=3
    }else{ //caja


      if (map>0){
      for (let index = 0; index < this.products.length; index++) {
        console.log("muestro  el produ")

        console.log(this.products[index])

  
      if (this.products[index].map==true && this.products[index].quantity!=0){

            this.seatsProducts.push(this.products[index])
      

      }

      this.mapLenght=this.seatsProducts.length

      this.map=true
      this.step=5

    }
    this.products = this.products.filter(product => product.map == false);// saco array

    
    }else{
      this.step++

    }

  }



  },


  startUserCreation1(selectedIds){
    console.log("ok ok ok")
    console.log(selectedIds)
    this.selectedSeats= this.selectedSeats.concat(selectedIds)
    if ((this.mapValidator+1)==this.mapLenght){
    this.map=false
    this.step=2

  }
  this.mapValidator++

  
},


  startGuestNames(name, last_name, phone, email, cc){
    this.name=name
    this.last_name=last_name
    this.phone=phone
    this.email=email
    this.cc=cc
    this.step++
  },


    startFinalSale(guests, id){
    
    this.ticketsTable=guests

    this.customer=id
    

    this.step++
  },

  completeSale(number, tck){

      console.log("lleue")

      let  conts = []

        
       
       if (this.pixelId ) {
        
      for (let index = 0; index < this.products.length; index++) {
        let cont = {id: this.products[index].id, quantity: 1, item_price: this.products[index].amount}

        conts.push(cont)
      
      }
      
      window.fbq('track', 'Purchase', {
          value: this.subtotal,
          currency: 'USD',
          contents: conts,
          content_type: 'product'
        });
      }
    
  
    
           
      if (number==2  ){
          this.$router.push({ name: "pending-confirmation", params: { id: this.event.slug } });
        }else{

          
          this.$router.push({ name: "confirmation", params: { id: this.event.slug}, query: { co: tck } });
        }
    
  },






          


    formatDate(dateString){
        const objectDate  = new Date(dateString);
        
        const year = objectDate.getFullYear();

const month = String(objectDate.getMonth() + 1).padStart(2, '0');

const day = String(objectDate.getDate()).padStart(2, '0');


        let format = day + "." + month + "." + year
              // Then specify how you want your dates to be formatted
         return format;
    },




    validateProc(){
      let format = false

      if(this.loadingEvent && !this.wanted){  

        format = false

      }else if (this.loadingEvent && this.wanted){
        format = true
      }else if (!this.loadingEvent && !this.wanted){
 
        format = true

      }else if(!this.loadingEvent && this.wanted){

        format = true
      }



         return format;
    },







  },
 

};
</script>

<style>


.vanilla-zoom {
  width: 100%;
}

.vanilla-zoom .sidebar {
  width: 100%;
  display: flex;
}

.vanilla-zoom .sidebar img.small-preview {
  width: 60px;
  margin-right: 5px;
  cursor: pointer;
  opacity: .5;
}

.vanilla-zoom .sidebar img.small-preview.active, .vanilla-zoom .sidebar img.small-preview:hover {
  opacity: 1;
}

.vanilla-zoom .sidebar img.small-preview:last-child {
  margin-right: 0;
}

.vanilla-zoom .zoomed-images {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}


.vanilla-zoom .container-phpto {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}




*, *::before, *::after {
  box-sizing: border-box;
}

.clean-block.clean-product .product-info .info .summary {
  border-top: 1px solid white;
  padding-top: 30px;
}

.clean-block.clean-product .gallery {
  padding: 40px;
  background-color: rgb(165, 157, 157);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}


 .searchcode{
       position: relative;
       box-shadow: 0 0 40px rgba(51, 51, 51, .1);
         
       }
   
       .searchcode .fa-search{

        position: absolute;
        top: 15px;
        left: 16px;

       }

       .searchcode button{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 110px;
        text-align: center;
        vertical-align: middle;
       }

       
.searchcode .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}


    
</style>